// @packages
import { Grid, Hidden } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

// @scripts
import Loader from 'src/components/atoms/Loader'
import { redirectTo } from 'src/utils/redirectTo'
import { useSelector } from 'src/store/types'
import { AuthForm } from './Form'

const useStyles = makeStyles(() => ({
  image: {
    height: '100vh',
    backgroundImage: 'url("/images/fondo-auth.jpg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30% top',
  },
}))

export const AuthBase = ({
  onSubmit,
  initialValues,
  validationSchema,
  isRegister,
}) => {
  const router = useRouter()
  const classes = useStyles()
  const user = useSelector(({ auth }) => auth.user)
  const token = useSelector(({ auth }) => auth.token)
  const openLoader = useSelector(({ commons }) => commons.loader)

  const redirectToRoute = useCallback(async () => {
    if (!user || !user.role || !token || token === '') return null
    if (!!router.query.next) return await router.replace(`/cursos/${router.query.next}`)
    return await router.replace(redirectTo(user.role))
  }, [user, token, router])

  useEffect(() => {
    (async () => {
      await redirectToRoute()
    })()
  }, [redirectToRoute])

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <AuthForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isRegister={isRegister}
          />
        </Grid>
        <Hidden only="xs">
          <Grid item sm={6} md={8} lg={8} xl={9} className={classes.image} />
        </Hidden>
      </Grid>
      <Loader open={openLoader} message="Cargando" />
    </>
  )
}
