import { FC, useState } from 'react'
import { Box, Divider, Grid, Icon, InputAdornment, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, Form, Formik } from 'formik'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { Button } from 'src/components/atoms/Button'
import { Image } from 'src/components/atoms/Image'
import { Typography } from 'src/components/atoms/Typography'
import { useBreakpoints } from 'src/hooks/useMediaQuery'

const SocialLogin = dynamic(() => import('src/components/Auth/SocialAuth'), { ssr: false })

type useStylesType = {
  isModal: boolean
}

const useStyles = makeStyles((theme) => ({
  form: {
    padding: ({ isModal }: useStylesType) => (!isModal ? '10px 3em' : '0 5em'),
    height: ({ isModal }: useStylesType) => (!isModal ? '100vh' : 'unset'),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: ({ isModal }: useStylesType) => isModal && 'unset !important',
    },
  },
  logo: {
    marginBottom: 10,
    '& h1': {
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: ({ isModal }: useStylesType) => (!isModal ? 'calc(.7em + 1vw)' : 'calc(.4em + 1vw)'),
      marginTop: ({ isModal }: useStylesType) => (!isModal ? 'unset' : 10),
      [theme.breakpoints.down('sm')]: {
        fontSize: ({ isModal }: useStylesType) => isModal && 'calc(.7em + 1vw)',
      },
    },
    '& .container': {
      display: 'contents',
      maxWidth: 250,
      '& span': {
        marginBottom: '5px !important',
      },
      '& img': {
        width: 'auto !important',
        minWidth: '0 !important',
      },
    },
  },

  divider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    textAlign: 'center',
    overflow: 'hidden',
    margin: '10px 0',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '10%',
      width: '35%',
      height: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: '10%',
      width: '35%',
      height: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  subtitle: {
    fontSize: ({ isModal }: useStylesType) => (!isModal ? 'calc(.7em + 1vw)' : 'calc(.4em + 1vw)'),
    lineHeight: '28px',
    color: '#757575',
    textAlign: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: ({ isModal }: useStylesType) => isModal && 'calc(.7em + 1vw)',
    },
  },
  dividerBottom: {
    margin: '20px 0 !important',
  },
  options: {
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
    fontSize: 14,
  },
  iconPassword: {
    cursor: 'pointer',
  },
  span: {
    fontWeight: 'bolder',
  },
  info: {
    color: '#757575',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '17px',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: ({ isModal }: useStylesType) => isModal && '13px',
    },
  },
  account: {
    fontSize: 14,
  },
  button: {
    color: '#FFF',
  },
  inputField: {
    marginBottom: 1,
    '& .MuiInputBase-input': {
      fontSize: 14,
      height: 15,
    },

    '& .MuiFormLabel-root': {
      fontSize: 14,
      marginTop: -1,
    },
  },
}))

interface AuthFormProps {
  onSubmit: (values: any, options: any) => void
  initialValues: any
  validationSchema: any
  isRegister: boolean
  isModal?: boolean
}

export const AuthForm: FC<AuthFormProps> = ({
  onSubmit,
  initialValues,
  validationSchema,
  isRegister,
  isModal,
}) => {
  const classes = useStyles({ isModal })
  const { xs } = useBreakpoints()

  const [viewPassword, setViewPassword] = useState<boolean>(false)

  const handleChangeViewPassword = () => setViewPassword((v) => !v)

  return (
    <div className={classes.form}>
      <div className={classes.logo}>
        <div className="container">
          <Image height={isModal && !xs ? 5 : 8} src="/images/logo-fondo-blanco.png" alt="logo" />
        </div>
        <Typography variant="h1" color="primary">
          Escuela de música online
        </Typography>
      </div>
      {
        isRegister && (
          <Typography className={classes.info} variant="h6">
            Aprende a tocar tu instrumento favorito
          </Typography>
        )
      }
      <div
        className={classes.subtitle}
      >
        {isRegister ? 'Regístrate con correo y contraseña' : 'Accede con correo y contraseña'}
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({
          errors,
          touched,
          isSubmitting,
          isValidating,
        }) => (
          <Form>
            {isRegister && (
              <Box marginBottom={1}>
                <Field
                  className={classes.inputField}
                  name="fullName"
                  type="text"
                  as={TextField}
                  label="Nombre completo*"
                  fullWidth
                  color="primary"
                  variant="outlined"
                  error={!!errors.fullName && touched.fullName}
                  helperText={touched.fullName && errors.fullName}
                />
              </Box>
            )}

            <Box marginBottom={1}>
              <Field
                className={classes.inputField}
                name="email"
                type="email"
                as={TextField}
                label="Correo*"
                fullWidth
                color="primary"
                variant="outlined"
                error={!!errors.email && touched.email}
                helperText={touched.email && errors.email}
              />
            </Box>

            <Box marginBottom={1}>
              <Field
                className={classes.inputField}
                name="password"
                type={viewPassword ? 'text' : 'password'}
                as={TextField}
                label="Contraseña*"
                fullWidth
                color="primary"
                variant="outlined"
                error={!!errors.password && touched.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div onClick={handleChangeViewPassword} className={classes.iconPassword}>
                        {viewPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button type="submit" disabled={isSubmitting || isValidating} fullWidth className={classes.button} variant="contained">
              {isRegister ? 'Registrarme' : 'Iniciar Sesión'}
            </Button>
            <div className={classes.divider}>O</div>
            <SocialLogin isRegister={isRegister} isModal={isModal} />
            <Divider className={classes.dividerBottom} />
            <Grid container flexDirection="column" justifyContent="center" alignItems="center" className={classes.options}>
              {!isRegister && (
                <Grid item>
                  <Link href="/recover-password" passHref>
                    <Typography variant="subtitle1" color="primary" className={classes.link}>
                      ¿Olvidaste tu contraseña?
                    </Typography>
                  </Link>
                </Grid>
              )}
              {
                !isModal && (
                  <Grid item>
                    <Grid container flexDirection="column">
                      <Grid item>
                        <Typography className={classes.account} color="secondary">
                          {isRegister ? '¿Ya tienes cuenta?' : '¿No tienes cuenta?'}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Link href={isRegister ? '/login' : '/register'} passHref>
                          <Typography variant="subtitle1" color="primary" className={classes.link}>
                            {isRegister ? 'Inicia sesión' : '¡Regístrate ahora!'}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  )
}
