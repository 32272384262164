import { useMediaQuery, useTheme } from '@mui/material'

export const useBreakpoints = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.only('md'))
  const sm = useMediaQuery(theme.breakpoints.only('sm'))
  const xs = useMediaQuery(theme.breakpoints.only('xs'))

  return {
    md,
    sm,
    xs,
  }
}
